<template>
    <div class="Container">
        <img src="../assets/Teaser2.png" class="Teaser"/>
    </div>
</template>

<script>

export default {
    name: 'Teaser',
}

</script>

<style scoped>

    .Container {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #0F0F0F;
    }

    .Teaser {
        height: 60%;
    }

</style>