import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.adapter = require('axios/lib/adapters/http');

const app = createApp(App)
app.use(router)
app.use(VueAxios, axios)
app.mount('#app')