<template>
    <div>
        
    </div>
</template>
<script>
export default {
    name: 'SingularityBlog'
}
</script>
<style scoped>
    
</style>